import * as React from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid2 as Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useTranslation } from "react-i18next";
import { newPasswordRules, validateRules } from "../utils/validation";

export interface ConfirmResetPasswordFormProps {
  onConfirmReset: (code: string, newPassword: string) => Promise<void>;
  onCancel: () => void;
  onResendConfirmationCode: () => Promise<void>;
}

const ConfirmResetPasswordForm = ({
  onConfirmReset,
  onCancel,
  onResendConfirmationCode,
}: ConfirmResetPasswordFormProps) => {
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] =
    React.useState(false);
  const [otp, setOtp] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmNewPassword, setConfirmNewPassword] = React.useState("");
  const [validationResults, setValidationResults] = React.useState({} as any);
  const { t } = useTranslation("translation");

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleMouseDownNewPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleClickShowConfirmNewPassword = () =>
    setShowConfirmNewPassword((show) => !show);
  const handleMouseDownConfirmNewPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleClickResendConfirmationCode = async () => {
    await onResendConfirmationCode();
  };
  const handleClickResetButton = async () => {
    if (validateInputs()) {
      await onConfirmReset(otp, newPassword);
    }
  };
  const handleClickCancelButton = () => onCancel();
  // const handleCodeChange = (
  //   event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   setOtp(event.target.value);
  // };
  const handleOptChange = (newValue: string) => {
    setOtp(newValue);
  };

  const handleChangeNewPassword = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setNewPassword(event.target.value);
  };

  const handleChangeConfirmNewPassword = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setConfirmNewPassword(event.target.value);
  };

  const handleBlurNewPassword = () => {
    const res = validateRules(newPassword, newPasswordRules);
    setValidationResults({
      ...validationResults,
      new_password: res === true ? "" : t(res),
    });
  };

  const handleBlurConfirmNewPassword = () => {
    setValidationResults({
      ...validationResults,
      confirm_new_password:
        newPassword === confirmNewPassword
          ? ""
          : t("err_msg_new_password_confirmation_mismatch"),
    });
  };

  const validateInputs = (): boolean => {
    const newPasswordResult = validateRules(newPassword, newPasswordRules);
    const confirmNewPasswordResult =
      newPassword === confirmNewPassword ||
      "err_msg_new_password_confirmation_mismatch";
    const results = {
      new_password: newPasswordResult === true ? "" : t(newPasswordResult),
      confirm_new_password:
        confirmNewPasswordResult === true ? "" : t(confirmNewPasswordResult),
    } as any;
    setValidationResults(results);
    for (const value of Object.values(results)) {
      if (value) {
        return false;
      }
    }
    return true;
  };

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12 }}>
        <Typography variant="body2">
          {t("hint_reset_password_confirm_code")}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <MuiOtpInput
          length={6}
          value={otp}
          onChange={handleOptChange}
        ></MuiOtpInput>
        {/* <TextField
          label={t("confirmation_code")}
          variant="outlined"
          type="number"
          value={otp}
          onChange={handleCodeChange}
          sx={{ width: "100%" }}
          InputProps={{
            type: "number",
            sx: {
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                {
                  display: "none",
                },
              "& input[type=number]": {
                MozAppearance: "textfield",
              },
            },
          }}
        ></TextField> */}
      </Grid>
      <Grid
        size={{ xs: 12 }}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button variant="text" onClick={handleClickResendConfirmationCode}>
          {t("resend_confirmation_code")}
        </Button>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <FormControl fullWidth variant="outlined">
          <InputLabel
            htmlFor="new-password-input"
            error={!!validationResults.new_password}
          >{`${t("new_password")}*`}</InputLabel>
          <OutlinedInput
            id="new-password-input"
            label={`${t("new_password")}*`}
            type={showNewPassword ? "text" : "password"}
            sx={{ width: "100%" }}
            value={newPassword}
            onChange={handleChangeNewPassword}
            onBlur={handleBlurNewPassword}
            error={!!validationResults.new_password}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowNewPassword}
                  onMouseDown={handleMouseDownNewPassword}
                  edge="end"
                >
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          ></OutlinedInput>
          {validationResults.new_password && (
            <FormHelperText id="new-password-helper-text" error>
              {validationResults.new_password}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <FormControl fullWidth variant="outlined">
          <InputLabel
            htmlFor="confirm-new-password-input"
            error={!!validationResults.confirm_new_password}
          >{`${t("confirm_new_password")}*`}</InputLabel>
          <OutlinedInput
            id="confirm-new-password-input"
            label={`${t("confirm_new_password")}*`}
            type={showConfirmNewPassword ? "text" : "password"}
            sx={{ width: "100%" }}
            value={confirmNewPassword}
            onChange={handleChangeConfirmNewPassword}
            onBlur={handleBlurConfirmNewPassword}
            error={!!validationResults.confirm_new_password}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfirmNewPassword}
                  onMouseDown={handleMouseDownConfirmNewPassword}
                  edge="end"
                >
                  {showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          ></OutlinedInput>
          {validationResults.confirm_new_password && (
            <FormHelperText id="confirm-new-password-helper-text" error>
              {validationResults.confirm_new_password}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Box sx={{ height: "24px", width: "100%" }}></Box>
      </Grid>
      <Grid size={{ xs: 6 }}>
        <Button
          variant="outlined"
          sx={{ width: "100%" }}
          onClick={handleClickCancelButton}
        >
          {t("cancel")}
        </Button>
      </Grid>
      <Grid size={{ xs: 6 }}>
        <Button
          variant="contained"
          sx={{ width: "100%" }}
          onClick={handleClickResetButton}
          disabled={!otp || !newPassword || newPassword !== confirmNewPassword}
        >
          {t("reset")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ConfirmResetPasswordForm;
