export const sortQueryFromOrderByRecord = (
  orderBy?: Record<string, "asc" | "desc">
): string[] | undefined => {
  if (!orderBy) return undefined;
  const sortQuery = [] as string[];
  for (const [key, value] of Object.entries(orderBy)) {
    if (value === "desc") {
      sortQuery.push(`-${key}`);
    } else {
      sortQuery.push(key);
    }
  }
  return sortQuery.length === 0 ? undefined : sortQuery;
};

export const deepEqual = (x: any, y: any): boolean => {
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y;
  return x && y && tx === "object" && tx === ty
    ? ok(x).length === ok(y).length &&
        ok(x).every((key) => deepEqual(x[key], y[key]))
    : x === y;
};
