import * as React from "react";
import { Avatar, Typography } from "@mui/material";
import { User } from "../types/user";
import { blue } from "@mui/material/colors";
import { Profile } from "../types/profile";

const avatarColors = [
  "#FFB900",
  "#D83B01",
  "#B50E0E",
  "#E81123",
  "#B4009E",
  "#5C2D91",
  "#0078D7",
  "#00B4FF",
  "#008272",
  "#107C10",
];

export function userAvatarColor(user?: User | null) {
  if (!!user?.deleted_at) {
    return "surface.300"
  }
  const key = userInitial(user);
  var sum = 0;
  for (let index = 0; index < key.length; index++) {
    sum += key.charCodeAt(index);
  }
  const i = sum % avatarColors.length;
  return avatarColors[i];
}

export function userInitial(user?: User | null) {
  let n = "";
  if (user) {
    if (user.first_name) {
      n += user.first_name.charAt(0).toUpperCase();
    }
    if (user.last_name) {
      n += user.last_name.charAt(0).toUpperCase();
    }
    if (n.length === 0 && user.name) {
      n += user.name.charAt(0).toUpperCase();
    }
    if (n.length === 0 && user.email) {
      n = user.email.charAt(0).toUpperCase();
    }
  }
  return n;
}

interface UserAvatarProps {
  user?: User | null;
}

const UserAvatar = ({ user }: UserAvatarProps) => {
  return user?.picture ? (
    <Avatar src={user.picture} />
  ) : (
    <Avatar sx={{ bgcolor: userAvatarColor(user) }}>
      {userInitial(user)}
    </Avatar>
  );
};

export default UserAvatar;
