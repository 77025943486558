import { create } from "zustand";
import { FetcherState } from "../types/fetcher";
import axios, {
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { useAuthStore } from "./auth";
import { useAppStore } from "./app";
import { useProfileStore } from "./profile";

const createFetch = (workspace?: string): AxiosInstance => {
  const baseURL = workspace
    ? `${process.env.GATSBY_OMS_API_URL}/app/ws/${workspace}`
    : `${process.env.GATSBY_OMS_API_URL}/app`;
  const fetcher = axios.create({ baseURL });

  fetcher.interceptors.request.use(
    (request: InternalAxiosRequestConfig) => {
      const token = useAuthStore.getState().token();
      if (token) {
        request.headers["Authorization"] = `Bearer ${token}`;
      }
      return request;
    },
    (error: any) => {
      console.log(`Error: Failed to send a request ${error}`);
    }
  );

  fetcher.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: any) => {
      const obj = JSON.parse(JSON.stringify(error));
      const config = obj.config;
      if (obj.status === 401) {
        // Retry
        useAuthStore
          .getState()
          .fetchSession()
          .then(() => {
            const token = useAuthStore.getState().token();
            config.headers["Authorization"] = `Bearer ${token}`;
            fetcher.request(config);
          }, (reason: any) => {
            throw reason;
          });
      } else if (obj.status === 503) {
        // Redirect to maintenance page
        useAppStore.getState().setMaintenanceRedirect(true);
      } else {
        console.log(`Error: Response (${obj.status}) ${JSON.stringify(error)}`);
        throw error;
      }
    }
  );
  return fetcher;
};

export const useFetcher = create<FetcherState>(
  (set, get) =>
    ({
      omsFetcher: createFetch(),
      omsDsFetcher: createFetch("global"),
      updateDsFetcher: (): AxiosInstance => {
        const domain = useProfileStore.getState().currentDomain;
        const omsDsFetcher = domain?.id
          ? createFetch(domain.id)
          : createFetch("global");
        set(() => ({ omsDsFetcher }));
        return omsDsFetcher;
      },
    } as FetcherState)
);
