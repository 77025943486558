exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-channels-[id]-tsx": () => import("./../../../src/pages/channels/[id].tsx" /* webpackChunkName: "component---src-pages-channels-[id]-tsx" */),
  "component---src-pages-channels-index-tsx": () => import("./../../../src/pages/channels/index.tsx" /* webpackChunkName: "component---src-pages-channels-index-tsx" */),
  "component---src-pages-channels-new-tsx": () => import("./../../../src/pages/channels/new.tsx" /* webpackChunkName: "component---src-pages-channels-new-tsx" */),
  "component---src-pages-click-collects-[id]-tsx": () => import("./../../../src/pages/click-collects/[id].tsx" /* webpackChunkName: "component---src-pages-click-collects-[id]-tsx" */),
  "component---src-pages-click-collects-index-tsx": () => import("./../../../src/pages/click-collects/index.tsx" /* webpackChunkName: "component---src-pages-click-collects-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-marketplace-apps-[id]-index-tsx": () => import("./../../../src/pages/marketplace-apps/[id]/index.tsx" /* webpackChunkName: "component---src-pages-marketplace-apps-[id]-index-tsx" */),
  "component---src-pages-marketplace-apps-[id]-oauth-redirect-tsx": () => import("./../../../src/pages/marketplace-apps/[id]/oauth-redirect.tsx" /* webpackChunkName: "component---src-pages-marketplace-apps-[id]-oauth-redirect-tsx" */),
  "component---src-pages-marketplace-apps-index-tsx": () => import("./../../../src/pages/marketplace-apps/index.tsx" /* webpackChunkName: "component---src-pages-marketplace-apps-index-tsx" */),
  "component---src-pages-marketplace-apps-new-tsx": () => import("./../../../src/pages/marketplace-apps/new.tsx" /* webpackChunkName: "component---src-pages-marketplace-apps-new-tsx" */),
  "component---src-pages-metadata-[id]-tsx": () => import("./../../../src/pages/metadata/[id].tsx" /* webpackChunkName: "component---src-pages-metadata-[id]-tsx" */),
  "component---src-pages-metadata-index-tsx": () => import("./../../../src/pages/metadata/index.tsx" /* webpackChunkName: "component---src-pages-metadata-index-tsx" */),
  "component---src-pages-metadata-new-tsx": () => import("./../../../src/pages/metadata/new.tsx" /* webpackChunkName: "component---src-pages-metadata-new-tsx" */),
  "component---src-pages-orders-[id]-tsx": () => import("./../../../src/pages/orders/[id].tsx" /* webpackChunkName: "component---src-pages-orders-[id]-tsx" */),
  "component---src-pages-orders-import-tsx": () => import("./../../../src/pages/orders/import.tsx" /* webpackChunkName: "component---src-pages-orders-import-tsx" */),
  "component---src-pages-orders-index-tsx": () => import("./../../../src/pages/orders/index.tsx" /* webpackChunkName: "component---src-pages-orders-index-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-roles-[id]-tsx": () => import("./../../../src/pages/roles/[id].tsx" /* webpackChunkName: "component---src-pages-roles-[id]-tsx" */),
  "component---src-pages-roles-index-tsx": () => import("./../../../src/pages/roles/index.tsx" /* webpackChunkName: "component---src-pages-roles-index-tsx" */),
  "component---src-pages-roles-new-tsx": () => import("./../../../src/pages/roles/new.tsx" /* webpackChunkName: "component---src-pages-roles-new-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-pages-users-[id]-tsx": () => import("./../../../src/pages/users/[id].tsx" /* webpackChunkName: "component---src-pages-users-[id]-tsx" */),
  "component---src-pages-users-index-tsx": () => import("./../../../src/pages/users/index.tsx" /* webpackChunkName: "component---src-pages-users-index-tsx" */),
  "component---src-pages-users-new-tsx": () => import("./../../../src/pages/users/new.tsx" /* webpackChunkName: "component---src-pages-users-new-tsx" */),
  "component---src-pages-workspaces-[id]-tsx": () => import("./../../../src/pages/workspaces/[id].tsx" /* webpackChunkName: "component---src-pages-workspaces-[id]-tsx" */),
  "component---src-pages-workspaces-index-tsx": () => import("./../../../src/pages/workspaces/index.tsx" /* webpackChunkName: "component---src-pages-workspaces-index-tsx" */),
  "component---src-pages-workspaces-new-tsx": () => import("./../../../src/pages/workspaces/new.tsx" /* webpackChunkName: "component---src-pages-workspaces-new-tsx" */)
}

