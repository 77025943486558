import * as React from "react";
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  CssBaseline,
  Snackbar,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import SignInForm from "./SignInForm";
import { AspectRatio } from "react-aspect-ratio";
import ResetPasswordForm from "./ResetPasswordForm";
import ConfirmResetPasswordForm from "./ConfirmResetPasswordForm";
import ChangePasswordForm from "./ChangePasswordForm";
import { useAuthStore } from "../stores/auth";
import { navigate } from "gatsby";

const Authenticator = () => {
  const [state, setState] = React.useState(
    "login" as
      | "login"
      | "forgot_password"
      | "reset_password"
      | "change_password"
  );
  const containerRef = React.useRef<HTMLElement>(null);
  const [forgotUsername, setForgotUsername] = React.useState("");
  const { t } = useTranslation("translation");
  const auth = useAuthStore();
  const [snackItem, setSnackItem] = React.useState(
    null as {
      text?: string;
      severity?: "success" | "info" | "warning" | "error";
    } | null
  );

  const handleClickForgotPassword = () => {
    setState("forgot_password");
  };
  const handleCancelResetPassword = () => {
    setState("login");
  };
  const handleContinueResetPassword = async (username: string) => {
    setForgotUsername(username);
    const output = await auth.resetPassword(username);
    switch (output.nextStep.resetPasswordStep) {
      case "CONFIRM_RESET_PASSWORD_WITH_CODE":
        setState("reset_password");
        break;
      default:
        setState("login");
    }
  };
  const handleCancelConfirmResetPassword = () => {
    setState("login");
  };

  const handleClickLogin = async (username: string, password: string) => {
    try {
      const output = await auth.signIn(username, password);
      switch (output.nextStep.signInStep) {
        case "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED":
          setState("change_password");
          break;
        case "RESET_PASSWORD":
          handleContinueResetPassword(username);
          break;
      }
      return output;
    } catch (err) {
      console.log(err);
      setSnackItem({
        text: t("snack_msg_sign_in_failed"),
        severity: "error",
      });
    }
  };

  const handleClickContinueWithMinor = async () => {
    return await auth.signInWithMinor();
  };

  const handleConfirmResetPassword = async (
    code: string,
    newPassword: string
  ) => {
    try {
      await auth.confirmResetPassword(forgotUsername, code, newPassword);
      setState("login");
      setSnackItem({
        text: t("snack_msg_conform_reset_password_success"),
        severity: "success",
      });
    } catch (err) {
      console.log(err);
      setSnackItem({
        text: t("snack_msg_conform_reset_password_failed"),
        severity: "error",
      });
    }
  };

  const handleResendConfirmationCode = async () => {
    const result = await auth.resetPassword(forgotUsername);
    return;
  };

  const handleConfirmSignInWithNewPassword = async (newPassword: string) => {
    try {
      await auth.confirmSignInWithNewPassword(newPassword);
      await auth.fetchSession();
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancelChangePassword = () => {
    setState("login");
  };

  const handleCloseSnackbar = () => {
    setSnackItem(null);
  };

  const banner = (
    <>
      <AspectRatio ratio="1024/420" className="w-full">
        <img src="/images/banner.png" />
      </AspectRatio>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "start",
          alignItems: "end",
          px: 2,
          py: 1,
        }}
      >
        <Typography variant="h5" noWrap sx={{ color: "white" }}>
          {t(state)}
        </Typography>
      </Box>
    </>
  );

  return (
    <Container
      maxWidth={false}
      sx={{
        width: "100vw",
        height: "100vh",
        bgcolor: { xs: "surface.main", sm: "primary.950" },
        p: { xs: 0, sm: "24px" },
      }}
    >
      <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
        <CssBaseline />
        <Box
          component="main"
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: { xs: "start", sm: "center" },
          }}
        >
          <Card sx={{ maxWidth: 480 }}>
            <CardMedia title="OMS Banner" sx={{ position: "relative" }}>
              {banner}
            </CardMedia>
            <CardContent>
              <Box ref={containerRef}>
                {state === "login" && (
                  <SignInForm
                    onClickLogin={handleClickLogin}
                    onClickContinueWithMinor={handleClickContinueWithMinor}
                    onClickForgot={handleClickForgotPassword}
                  ></SignInForm>
                )}
                {state === "forgot_password" && (
                  <ResetPasswordForm
                    onCancel={handleCancelResetPassword}
                    onContinue={handleContinueResetPassword}
                  ></ResetPasswordForm>
                )}
                {state === "reset_password" && (
                  <ConfirmResetPasswordForm
                    onCancel={handleCancelConfirmResetPassword}
                    onConfirmReset={handleConfirmResetPassword}
                    onResendConfirmationCode={handleResendConfirmationCode}
                  ></ConfirmResetPasswordForm>
                )}
                {state === "change_password" && (
                  <ChangePasswordForm
                    hideCurrentPassword
                    onCancel={handleCancelChangePassword}
                    onChangePassword={handleConfirmSignInWithNewPassword}
                  ></ChangePasswordForm>
                )}
                {/* <Slide direction="right" in={state === "reset_password"} container={containerRef.current}>
                  <Box sx={{bgcolor: "primary.400", width:"100%", height: 360}}></Box>
                </Slide> */}
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={!!snackItem?.text}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            // onClose={handleCloseSnackbar}
            severity={snackItem?.severity ?? "success"}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackItem?.text ?? ""}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default Authenticator;
