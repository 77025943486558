import { create } from "zustand";
import {
  UpdateOrder,
  Order,
  OrdersQuery,
  CreateOrder,
  ListOrders,
  OrdersState,
  CancelValidteOutput,
  ImportOrdersInput,
  ImportOrdersOutput,
  BulkOrdersOperationInput,
  BulkOrdersOperationOutput,
} from "../types/order";
import { useFetcher } from "./fetcher";
import { Paging } from "../types/paging";
import { sortQueryFromOrderByRecord } from "../utils/query";
import { ListOrderEvents, OrderEvent } from "../types/order_event";

export const useOrdersStore = create<OrdersState>(
  (set, get) =>
    ({
      items: [],
      total: null,
      query: null,
      showFilter: true,
      limit: 20,
      selected: [],
      selectAll: false,
      search: async (query?: OrdersQuery, fetch?: boolean) => {
        set(() => ({ query }));
        if (fetch !== false) {
          await get().fetch(true);
        }
      },
      export: async (query?: OrdersQuery): Promise<void> => {
        const sort = sortQueryFromOrderByRecord(query?.order_by);
        const params: any = query
          ? {
              ...query,
              order_by: undefined,
              sort,
              offset: 0,
            }
          : { offset: 0 };
        await useFetcher.getState().omsDsFetcher.get("orders", {
          headers: {
            Accept: "text/csv",
          },
          params,
          paramsSerializer: {
            indexes: null,
          },
        });
      },
      fetch: async (reset: boolean): Promise<Paging<Order> | null> => {
        try {
          const { items, limit, query, total, selected, selectAll } = get();
          if (!reset && total !== null && total <= items.length) {
            return null;
          }
          const sort = sortQueryFromOrderByRecord(query?.order_by);
          const params: any = query
            ? {
                ...query,
                order_by: undefined,
                sort,
                limit,
                offset: reset ? 0 : items.length,
              }
            : { limit, offset: reset ? 0 : items.length };
          const res = await useFetcher
            .getState()
            .omsDsFetcher.get<Paging<Order>>("orders", {
              params,
              paramsSerializer: {
                indexes: null,
              },
            });

          set(() => ({
            items: [...(reset ? [] : items), ...(res?.data?.items ?? [])],
            total: res?.data?.total ?? null,
            selected: selectAll
              ? [...(reset ? [] : selected), ...(res?.data?.items ?? [])]
              : reset
              ? []
              : selected,
          }));
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      list: async (input?: ListOrders): Promise<Paging<Order>> => {
        try {
          const { limit, offset, order_by, ...query } = input
            ? input
            : { limit: 20, offset: 0, order_by: undefined };
          const sortQuery = sortQueryFromOrderByRecord(order_by);
          const params: any = query
            ? {
                ...query,
                order_by: undefined,
                limit: limit ? limit : 20,
                offset: offset ? offset : 0,
                sort: sortQuery,
              }
            : {
                limit: limit ? limit : 20,
                offset: offset ? offset : 0,
                sort: sortQuery,
              };
          const res = await useFetcher
            .getState()
            .omsDsFetcher.get<Paging<Order>>("orders", {
              params,
              paramsSerializer: {
                indexes: null,
              },
            });
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      get: async (id: number): Promise<Order | null> => {
        try {
          const res = await useFetcher
            .getState()
            .omsDsFetcher.get<Order>(`orders/${id}`);
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      create: async (input: CreateOrder): Promise<Order | null> => {
        try {
          const res = await useFetcher
            .getState()
            .omsFetcher.post<Order>(`orders`, input);
          get().clear();
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      update: async (id: number, input: UpdateOrder): Promise<Order | null> => {
        try {
          const res = await useFetcher
            .getState()
            .omsFetcher.patch<Order>(`orders/${id}`, input);
          if (res.data) {
            const items = [...get().items];
            const idx = items.findIndex((value) => {
              return value.id === id;
            });
            if (idx >= 0) {
              items[idx] = { ...res.data };
              set(() => ({ items: items }));
            }
          }
          return res.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      delete: async (id: number): Promise<void> => {
        try {
          const res = await useFetcher
            .getState()
            .omsFetcher.delete(`orders/${id}`);
          get().clear();
        } catch (error) {
          throw error;
        }
      },
      clear: () => {
        set(() => ({
          items: [],
          total: null,
          query: null,
          selected: [],
          selectAll: false,
        }));
      },
      listEvents: async (
        id: number,
        input?: ListOrderEvents
      ): Promise<Paging<OrderEvent>> => {
        try {
          const { limit, offset, order_by, ...query } = input
            ? input
            : { limit: 20, offset: 0, order_by: undefined };
          const sortQuery = sortQueryFromOrderByRecord(order_by);
          const params: any = query
            ? {
                ...query,
                limit: limit ? limit : 20,
                offset: offset ? offset : 0,
                sort: sortQuery,
              }
            : {
                limit: limit ? limit : 20,
                offset: offset ? offset : 0,
                sort: sortQuery,
              };
          const res = await useFetcher
            .getState()
            .omsDsFetcher.get<Paging<OrderEvent>>(`orders/${id}/events`, {
              params,
              paramsSerializer: {
                indexes: null,
              },
            });
          return res?.data ?? null;
        } catch (error) {
          throw error;
        }
      },
      createSalesOrder: async (id: number): Promise<void> => {
        try {
          await useFetcher
            .getState()
            .omsDsFetcher.post(`orders/${id}/sales-order`);
        } catch (error) {
          throw error;
        }
      },
      createPackingOrder: async (id: number): Promise<void> => {
        try {
          await useFetcher
            .getState()
            .omsDsFetcher.post(`orders/${id}/pick-pack`);
        } catch (error) {
          throw error;
        }
      },
      createDelivery: async (id: number, boxQty: number): Promise<void> => {
        try {
          await useFetcher
            .getState()
            .omsDsFetcher.post(`orders/${id}/delivery`, {
              box_qty: boxQty,
            });
        } catch (error) {
          throw error;
        }
      },
      cancelOrder: async (id: number, reasons?: string): Promise<void> => {
        try {
          await useFetcher.getState().omsDsFetcher.post(`orders/${id}/cancel`, {
            reasons,
          });
        } catch (error) {
          throw error;
        }
      },
      createShipmentLabel: async (id: number): Promise<void> => {
        // Request to marketplace for a new shipment label
        // Marketplace:
        //   - shopee
        // Note: For lazada, we just download it. No need to send a request to create the shipment label.
        try {
          await useFetcher
            .getState()
            .omsDsFetcher.post(`orders/${id}/shipment-label`);
        } catch (error) {
          throw error;
        }
      },
      pullShipmentLabel: async (id: number): Promise<void> => {
        // Pull shipment label from marketplace to OMS backend's S3
        // Marketplace:
        //   - shopee
        //   - lazada
        try {
          await useFetcher
            .getState()
            .omsDsFetcher.post(`orders/${id}/pull-shipment-label`);
        } catch (error) {
          throw error;
        }
      },
      uploadShipmentLabel: async (
        id: number,
        shipmentLabelFile: File
      ): Promise<void> => {
        try {
          const buffer = await shipmentLabelFile.arrayBuffer();
          const file = new File([buffer], shipmentLabelFile.name, {
            type: shipmentLabelFile.type,
          });

          const formData = new FormData();
          formData.append("file", file);

          await useFetcher
            .getState()
            .omsDsFetcher.put(`orders/${id}/shipment-label`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
        } catch (error) {
          console.log("failed to upload shipment label");
          throw error;
        }
      },
      getShipmentLabelFile: async (id: number): Promise<File | null> => {
        const order = await get().get(id);
        if (order?.shipment_label_url) {
          // order.shipment_label_url = "https://s3.ap-southeast-1.amazonaws.com/mll-oms-doc-dev.minorlifestyle.com/orders/1704/documents/shipment_label-TCI00000001.pdf?response-content-disposition=inline&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Security-Token=IQoJb3JpZ2luX2VjENP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDmFwLXNvdXRoZWFzdC0xIkcwRQIhAMo%2B5Jy5aVAPcp11zOHksznwY%2FLKHWLeQCZzc8HWSBaqAiBusmFR8ynelJVyD7mKDnkQzhOoPIqy2e3VVYp%2BEwmDLCrMAwg8EAAaDDg5MTM3Njk2NTk5NiIM49sebyeIQtzlj%2FgtKqkDaV%2BPdMxSSDqcfK0EDXhajTmhu1EoRp4q8N%2BmNZ046mhylyqfFIeZdvARyH54wWbYC011zmrXf9ijqsGcYLCc8wr2AV%2FMCUaDN4A3Qrqwj18YBhfkewfA4%2BLOlPeYzMWgITbCw%2Bhs0u0JSeOlTTCE%2ByS3dkqGAfWA6ee1ANvOc9jOE7sRJIp1sSuZOkiEwIlhBLUmsTVfDl412N9%2BYE2aqCkAraQ9spRuRij73pAxQPRDVRlGHu6bX7nJo8QgrIQz2qJZ8pPOrPmZBGp7Cs%2Fo4tGSMCBvx0lLvnlTg4w5ZHwuo1Psry9Aklvou1RR2U8fnBIFo3nwcnFgB9dgXKqii4XCnTerT9X5oM5jByY1c4BlLGX1Aj%2FckkjEKtPBMyXeB3SvDusI2PF9PdHTmrsiq2VGnrNoNpf670Nva9IvBqUkKmRcAyCXewrV6xOI0rKH%2F%2B%2BuKMM52DC8kum2zWI6vi%2FaQf10jvlEvMAbUhdPDqyd1PkMH9SxBhgTdqPVIzbKPPD2Igh3SaImNU%2FBCLOt8zuTDolCmJbvc2kjcbfbmkNKKwBAvg%2B9CHgwttySvwY6twLXgG11s%2FVbSOe8v7hZdqE2gNijqZcvslDRl1LBFU2wFBMZfpARwminpJAUOt9NxQLuW7D%2Bt%2FjQLzmjR8cWIS7O0ls70Bm5JZRzkXv2sy90Jo3Wl8LBHmetI8G%2BV354uG9X8060cncjNYkDS%2FgQFbZIcz5Mjx7TgiYSnqvCZ1%2FXBugdJxc4%2FmmG4a4MJl21Ar1WEhoNtKngPlvzNezm2IUmsBuWj9O%2BbjCLRfScZu0GeBGWpEh1sqCfq5RzUiRwY5X245MIwtVss6QkHwsSG5vL9%2FdY5q7ME1Nvg%2BdQBZRwkIrWnTSaNM%2FWhKRF1x8Esx3j9CEyA8NDwvO9sH8TTkbf7Ak3l0lgztWn6Fw6LjBlPXteuM56jJb%2FsCNeRw5mEnE2kkiFUHqRCgGwDk8U%2F6NVjeqnTsZUww%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA47CRVDFWKDP2NMCQ%2F20250327%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20250327T023054Z&X-Amz-Expires=43200&X-Amz-SignedHeaders=host&X-Amz-Signature=e2930159cc76b700cb83cb84b977a3aef8e0fa5825e9fc730801fecb29363535"
          const filename = order.shipment_label_url
            .split("?")[0]
            .replace(/^.*[\\/]/, "");
          if (
            order.shipment_label_url.indexOf("://") > 0 ||
            order.shipment_label_url.indexOf("//") === 0
          ) {
            try {
              const resp = await fetch(order.shipment_label_url, {
                method: "GET",
              });
              const blob = await resp.blob();
              const contentType =
                resp.headers.get("content-type") ??
                resp.headers.get("Content-Type") ??
                "application/pdf";
              const file = new File([blob], filename, { type: contentType });
              return file;
            } catch (error) {
              console.log(
                `failed to download shipment label from external url ${error}`
              );
              throw error;
            }
          } else {
            try {
              const resp = await useFetcher
                .getState()
                .omsDsFetcher.get(`orders/${id}/shipment-label`, {
                  headers: {
                    Accept: "application/pdf",
                  },
                  responseType: "blob",
                });
              console.log(
                `successfully to download shipment label from oms ${filename}`
              );
              const contentType =
                resp.headers["content-type"] ??
                resp.headers["Content-Type"] ??
                "application/pdf";
              const file = new File([resp.data], filename, {
                type: contentType,
              });
              return file;
            } catch (error) {
              console.log(
                `failed to download shipment label from oms ${error}`
              );
              throw error;
            }
          }
        }
        return null;
      },
      readyToShip: async (id: number): Promise<void> => {
        // Notify the marketplace that the order is ready to ship
        // Marketplace:
        //   - lazada
        try {
          await useFetcher
            .getState()
            .omsDsFetcher.post(`orders/${id}/ready-to-ship`);
        } catch (error) {
          throw error;
        }
      },
      pullUpdate: async (id: number): Promise<void> => {
        // Pull update the order information
        // Marketplace:
        //   - shopee
        //   - lazada
        try {
          await useFetcher
            .getState()
            .omsDsFetcher.post(`orders/${id}/pull-update`);
        } catch (error) {
          throw error;
        }
      },
      returnOrder: async (id: number): Promise<void> => {
        try {
          await useFetcher.getState().omsDsFetcher.post(`orders/${id}/return`);
        } catch (error) {
          throw error;
        }
      },
      completeOrder: async (id: number): Promise<void> => {
        try {
          await useFetcher
            .getState()
            .omsDsFetcher.post(`orders/${id}/complete`);
        } catch (error) {
          throw error;
        }
      },
      cancelValidate: async (id: number): Promise<CancelValidteOutput> => {
        try {
          const res = await useFetcher
            .getState()
            .omsDsFetcher.post<CancelValidteOutput>(
              `orders/${id}/cancel-validate`
            );
          return res.data;
        } catch (error) {
          throw error;
        }
      },
      responseCancellationRequest: async (
        id: number,
        response: "accept" | "reject"
      ): Promise<void> => {
        try {
          await useFetcher
            .getState()
            .omsDsFetcher.post(`orders/${id}/response-cancellation-request`, {
              response,
            });
        } catch (error) {
          throw error;
        }
      },
      import: async (input: ImportOrdersInput): Promise<ImportOrdersOutput> => {
        if (input.file) {
          const formData = new FormData();
          formData.append("file", input.file);
          const res = await useFetcher
            .getState()
            .omsDsFetcher.post<ImportOrdersOutput>(
              "bulk-orders/import",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          get().clear();
          return res.data;
        } else if (input.orders) {
          const res = await useFetcher
            .getState()
            .omsDsFetcher.post<ImportOrdersOutput>("bulk-orders/import", {
              orders: input.orders,
            });
          get().clear();
          return res.data;
        } else {
          throw new Error("No file or orders provided");
        }
      },
      setShowFilter: async (value: boolean): Promise<void> => {
        set(() => ({
          showFilter: value,
        }));
      },
      setSelectAll: (value: boolean) => {
        set((state: OrdersState) => ({
          selectAll: value,
          selected: value ? [...state.items] : state.selected,
        }));
      },
      setSelected: (items: Order[]) => {
        set((state: OrdersState) => ({
          selected: [...items],
          selectAll: items.length === state.items.length,
        }));
      },
      addSelected: (order: Order) => {
        set((state: OrdersState) => {
          const selected = [...state.selected, order];
          return {
            selected,
            selectAll: selected.length === state.items.length,
          };
        });
      },
      removeSelected: (id: number) => {
        set((state: OrdersState) => ({
          selected: state.selected.filter((v: Order) => v.id !== id),
          selectAll: false,
        }));
      },
      isSelected: (id: number): boolean => {
        return get().selected.findIndex((o: Order) => o.id === id) >= 0;
      },
      clearSelected: () => {
        set(() => ({
          selected: [],
          selectAll: false,
        }));
      },
      bulkComplete: async (
        input: BulkOrdersOperationInput
      ): Promise<BulkOrdersOperationOutput> => {
        try {
          const resp = await useFetcher
            .getState()
            .omsDsFetcher.post<BulkOrdersOperationOutput>(
              `bulk-orders/complete`,
              input
            );
          get().clear();
          return resp.data;
        } catch (error) {
          throw error;
        }
      },
    } as OrdersState)
);
