import * as React from "react";
import {
  Box,
  CSSObject,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Theme,
  Typography,
  styled,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import {
  AdminPanelSettings,
  ChevronLeft,
  ChevronRight,
  Dashboard,
  GroupWork,
  Hub,
  People,
  ReceiptLong,
  Settings,
  ShoppingBasket,
  Storefront,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../stores/app";
import { navigate } from "gatsby";
import { useProfileStore } from "../stores/profile";

const expandedDrawerWidth = 240;
const collapsedDrawerWidth = 64;

const DrawerHeader = styled("div")(({ theme }: any) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: expandedDrawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop: any) => prop !== "open",
})(({ theme, open }: any) => ({
  width: expandedDrawerWidth,
  flexShrink: 0,
  height: "100%",
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface AppSideMenuItem {
  label: string;
  icon: React.ReactNode;
  href: string;
  permitted_resource?: string; // Resource to check with the permission
  permitted_action?: "list" | "get" | "create" | "edit" | "delete" | "view"; // Action to check with the permission
  global_only?: boolean;
  onClick?: (event?: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

const AppSideMenu = () => {
  const app = useAppStore();
  const profileStore = useProfileStore();
  const { t } = useTranslation("translation");

  const expanded = app.sideMenuExpanded;
  const sideMenuVisible = app.sideMenuVisible;

  const handleDrawerExpandToggle = () => {
    const expand = app.sideMenuExpanded;
    app.setSideMenuExpanded(!expand);
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      app.setSideMenuVisible(open);
    };

  const menuItems = [
    {
      label: t("dashboard"),
      icon: <Dashboard />,
      href: "/",
      permitted_resource: "dashboard",
      permitted_action: "view",
    },
    {
      label: t("orders"),
      icon: <ReceiptLong />,
      href: "/orders",
      permitted_resource: "orders",
      permitted_action: "view",
    },
    {
      label: t("click_collect"),
      icon: <ShoppingBasket />,
      href: "/click-collects",
      permitted_resource: "click-collects",
      permitted_action: "view",
    },
    { label: "-" },
    {
      label: t("users"),
      icon: <People />,
      href: "/users",
      permitted_resource: "users",
      permitted_action: "view",
      global_only: true,
    },
    {
      label: t("roles"),
      icon: <AdminPanelSettings />,
      href: "/roles",
      permitted_resource: "roles",
      permitted_action: "view",
      global_only: true,
    },
    {
      label: t("channels"),
      icon: <Hub />,
      href: "/channels",
      permitted_resource: "channels",
      permitted_action: "view",
    },
    {
      label: t("workspaces"),
      icon: <GroupWork />,
      href: "/workspaces",
      permitted_resource: "workspaces",
      permitted_action: "view",
      global_only: true,
    },
    {
      label: t("marketplace_apps"),
      icon: <Storefront />,
      href: "/marketplace-apps",
      permitted_resource: "marketplace-apps",
      permitted_action: "view",
      global_only: true,
    },
    {
      label: t("settings"),
      icon: <Settings />,
      href: "/settings",
      permitted_resource: "settings",
      permitted_action: "view",
      global_only: true,
    },
  ] as AppSideMenuItem[];

  const permittedMenuItem = () => {
    const currentDomain = profileStore.currentDomain?.id ?? "global"
    return menuItems.filter((item: AppSideMenuItem) => {
      if (item.global_only && currentDomain !== "global") {
        return false
      }
      if (item.permitted_resource && item.permitted_action) {
        return profileStore.hasPermission(
          item.permitted_resource,
          item.permitted_action
        );
      }
      return true;
    });
  };

  const handleClickMenuItem = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent> | undefined,
    item: AppSideMenuItem
  ) => {
    if (item.onClick) {
      item.onClick(event);
    } else if (item.href) {
      navigate(item.href);
      event?.preventDefault();
    }
  };

  const drawerItems = ({ expanded }: any) => (
    <>
      <DrawerHeader></DrawerHeader>
      <Divider />
      <List>
        {permittedMenuItem().map((item: any, index) =>
          item.label === "-" ? (
            <Divider sx={{ my: 1 }} key={`divider-${index}`} />
          ) : (
            <ListItem
              key={item.label}
              disablePadding
              sx={{ display: "block" }}
              onClick={(event) => {
                handleClickMenuItem(event, item);
              }}
            >
              <ListItemButton
                component="a"
                href={item.href}
                sx={{
                  minHeight: 48,
                  justifyContent: expanded ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: expanded ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography color="surface.contrastText" variant="body1">
                      {item.label}
                    </Typography>
                  }
                  sx={{ opacity: expanded ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
    </>
  );

  return (
    <Box
      component="nav"
      sx={{
        width: {
          md: app.sideMenuExpanded ? expandedDrawerWidth : collapsedDrawerWidth,
        },
        flexShrink: { md: 0 },
      }}
      id="app-side-menu-box"
    >
      <SwipeableDrawer
        open={sideMenuVisible}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: expandedDrawerWidth,
          },
        }}
      >
        {drawerItems({ expanded: true })}
      </SwipeableDrawer>

      <Drawer
        variant="permanent"
        open={expanded}
        sx={{
          display: { xs: "none", md: "block" },
        }}
      >
        {drawerItems({ expanded })}
        <Box sx={{ flexGrow: 1 }}></Box>
        <Divider sx={{ display: { xs: "none", md: "block" } }} />
        <List
          sx={{
            flexShrink: 0,
            flexGrow: 0,
            display: { xs: "none", md: "block" },
          }}
        >
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={handleDrawerExpandToggle}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: expanded ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {expanded ? <ChevronLeft /> : <ChevronRight />}
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
};

export default AppSideMenu;
