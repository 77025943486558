import * as React from "react";
import { Box, Button, Grid2 as Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { emailRules, validateRules } from "../utils/validation";

export interface ResetPasswordFormProps {
  onContinue: (username: string) => void;
  onCancel: () => void;
}

const ResetPasswordForm = ({
  onContinue,
  onCancel,
}: ResetPasswordFormProps) => {
  const [username, setUsername] = React.useState("");
  const [validationResults, setValidationResults] = React.useState({} as any);
  const {t} = useTranslation("translation");
  const handleClickContinueButton = () => {
    if (validateInputs()) {
      onContinue(username);
    }
  };
  const handleClickCancelButton = () => onCancel();
  const handleChangeUsername = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setUsername(event.target.value);
  };

  const handleBlurUsername = () => {
    const res = validateRules(username, emailRules);
    setValidationResults({
      ...validationResults,
      username: res === true ? "" : t(res),
    });
  };

  const validateInputs = (): boolean => {
    const usernameResult = validateRules(username, emailRules);
    const results = {
      username: usernameResult === true ? "" : t(usernameResult),
    } as any;
    setValidationResults(results);
    for (const value of Object.values(results)) { 
      if (value) {
        return false;
      }
    }
    return true;
  };

  return (
    <Grid container spacing={2}>
      <Grid size={{xs: 12}}>
        <Typography variant="body2">
          {t("hint_reset_passsword_email")}
        </Typography>
      </Grid>
      <Grid size={{xs: 12}}>
        <TextField
          label={`${t("email_address")}*`}
          variant="outlined"
          type="email"
          value={username}
          onChange={handleChangeUsername}
          onBlur={handleBlurUsername}
          error={!!validationResults.username}
          helperText={validationResults.username}
          sx={{ width: "100%" }}
        ></TextField>
      </Grid>
      <Grid size={{xs: 12}}>
        <Box sx={{ height: "24px", width: "100%" }}></Box>
      </Grid>
      <Grid size={{xs: 6}}>
        <Button
          variant="outlined"
          sx={{ width: "100%" }}
          onClick={handleClickCancelButton}
        >
          {t("cancel")}
        </Button>
      </Grid>
      <Grid size={{xs: 6}}>
        <Button
          variant="contained"
          sx={{ width: "100%" }}
          onClick={handleClickContinueButton}
          disabled={!username}
        >
          {t("continue")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ResetPasswordForm;
